export const NoneUnknownErrorStr = "Property Loss must be either 'None' or 'Unknown'";

export const ErrorTooltip = ({ ErrorStr }) => (<span className='hovertext' style={{ marginLeft: '15px' }} data-hover={ErrorStr} ><i className='fa fa-exclamation-circle'></i></span>);

export const TableErrorTooltip = ({ ErrorStr, value }) => { return (<span className='hovertext' data-hover={ErrorStr} >{value} <i className='fa fa-exclamation-circle'></i></span>) };

export const ErrorShow = ({ ErrorStr }) => (<span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{ErrorStr}</span>);

export const NibrsErrorStyle = (status) => {
    const colour_Styles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: status ? "rgb(255 202 194)" : "#fce9bf",
            height: 20,
            minHeight: 32,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };
    return colour_Styles;
};

// 39A, 39B, 39C, 39C, 200, 510, 220, 250, 290, 35A, 35B, 270, 210, 26A, 26B, 26C, 26D, 26E, 26F, 100, 23A-23G, 240, 120, 280, 26H, 521, 522, 526, 58A, 58B, 61A, 61B, 620

export const check_OffenceCode_NoneUnknown = (nibrsCode, lossCode, AttmComp) => {
    // console.log("🚀 ~ constcheck_OffenceCode_NoneUnknown= ~ lossCode:", lossCode);
    // console.log("🚀 ~ constcheck_OffenceCode_NoneUnknown= ~ AttmComp:", AttmComp);
    // console.log("🚀 ~ constcheck_OffenceCode_NoneUnknown= ~ nibrsCode:", nibrsCode);
    switch (nibrsCode) {
        case '39A':
        case '39B':
        case '39C':
        case '39D': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'CON') ? 'Loss Code must be Sized' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '200': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'BURN') ? 'Loss Code must be Burned Property' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '510': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'RECD' && lossCode !== 'STOL' && lossCode !== 'Unknown' && lossCode !== 'None') ? 'Loss Code must be Recovered, stolen, None and Unknown' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '220': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'None' && lossCode !== 'RECD' && lossCode !== 'STOL' && lossCode !== 'Unknown') ? 'Loss Code must be None, Recoverd, Stolen or Unknown' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '250': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'FORG' && lossCode !== 'RECD' && lossCode !== 'CON') ? 'Loss Code must be  Counterfeited/Forged, Recoverd, Sized' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '290': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'DAMA') ? 'Loss Code must be estroyed/Damaged/Vandalized ' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '35A':
        case '35B': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'None' && lossCode !== 'CON') ? 'Loss Code must be None or Unknown, sized' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '270':
        case '210':
        case '26A':
        case '26B':
        case '26C':
        case '26E':
        case '26F':
        case '26G': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'RECD' && lossCode !== 'STOL') ? 'Loss Code must be Recovered, Stolen' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '100': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'None' && lossCode !== 'RECD' && lossCode !== 'STOL' && lossCode !== 'Unknown') ? 'Loss Code must be None, Recovered, Stolen or Unknown' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '23A':
        case '23B':
        case '23C':
        case '23D':
        case '23E':
        case '23F':
        case '23G':
        case '23H': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'None' && lossCode !== 'RECD' && lossCode !== 'STOL') ? 'Loss Code must be None, Recoverd, Stolen' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '240':
        case '120': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'RECD' && lossCode !== 'STOL') ? 'Loss Code must be None, Recoverd' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '280': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'None' && lossCode !== 'RECD' && lossCode !== 'STOL') ? 'Loss Code must be None, Recoverd, Stolen' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '26H': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'RECD' && lossCode !== 'STOL') ? 'Loss Code must be None, Recoverd, Stolen' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '521':
        case '522':
        case '526': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'None' && lossCode !== 'CON') ? 'Loss Code must be None, Sized' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '58A': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'None' && lossCode !== 'CON') ? 'Loss Code must be None, Sized' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '58B': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'CON' && lossCode !== 'FORG' && lossCode !== 'RECD') ? 'Loss Code must be Counterfeited/Forged, Recoverd, or Sized' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        case '61A':
        case '61B':
        case '620': {
            switch (AttmComp) {
                case 'A': {
                    return (lossCode !== 'None' && lossCode !== 'Unknown') ? 'Loss Code must be None or Unknown' : '';
                }
                case 'C': {
                    return (lossCode !== 'None' && lossCode !== 'CON') ? 'Loss Code must be None, Unknown or Sized' : '';
                }
                default: {
                    return ''; // Added return statement for default case
                }
            }
        }
        default: {
            return ''; // Added return statement for default case
        }
    }
}



// Unknown = Unknown
// None =  None
// Recovered = RECD
// BURN = Burned Property
// sized = CON
// Counterfeited/Forged = FORG
// Destroyed/Damaged/Vandalized = DAMA
// Stolen = STOL



// export const check_OffenceCode_NoneUnknown = (nibrsCode, lossCode, AttmComp) => {

//     // console.group()
//     // console.log("🚀 ~ constcheck_OffenceCode_NoneUnknown= ~ nibrsCode:", nibrsCode);
//     // console.log("🚀 ~ constcheck_OffenceCode_NoneUnknown= ~ lossCode:", lossCode);
//     // console.log("🚀 ~ constcheck_OffenceCode_NoneUnknown= ~ AttmComp:", AttmComp);
//     // console.groupEnd()

//     // const CodeArray = ['100', '35', '521', '522', '526', '26H'];

//     // const burnOrSizedCodeArray = ['35A', '35B', '521', '522', '526', '58A', '61A', '61B', '620']

//     // const recoStolenCodeArray = [
//     //     '120', '210', '23A', '23B', '23C', '23D', '23E', '23F', '23G', '23H', '240', '26A', '26B', '26C', '26D', '26E', '26F', '26G', '26H', '270',
//     // ]

//     // const noneReacStolCodeArray = ['100', '220', '510']

//     // const noneCodeArray = [
//     //     '200', '510', '220', '250', '290', '35A', '35B', '270', '210', '26A', '26B', '26C', '26D', '26E', '26F', '26G', '39A', '39B', '39C', '39D', '100', '23A', '23B', '23C', '23D', '23E', '23F', '23G', '23H', '240', '120', '280', '26H', '521', '522', '526', '58A', '58B', '61A', '61B', '620',
//     // ]

//     const recoverdCodeArray = [
//         '510', '220', '250', '270', '210', '26A', '26B', '26', '26D', '26E', '26F', '26G', '26H', '100', '23A', '23B', '23C', '23D', '23E', '23F', '23G', '23H', '58B', '240', '120', '280',
//     ]

//     const sizedCodeArray = [
//         '250', '35A', '35B', '39A', '39B', '39C', '39D', '521', '522', '526', '58A', '58B', '61A', '61B', '620',
//     ]

//     const stolencodeArr = [
//         '510', '220', '270', '210', '26A', '26B', '26', '26D', '26E', '26F', '26G', '26H', '100', '23A', '23B', '23C', '23D', '23E', '23F', '23G', '23H', '240', '120',
//     ]

//     const sizedLossCodeArr = ['250', '35A', '35B', '39A', '39B', '39C', '39D', '521', '522', '526', '58A', '58B', '61A', '61B', '620',]

//     if (lossCode) {
//         // A
//         // if (CodeArray?.includes(nibrsCode) && lossCode != 'None' && lossCode != 'Unknown' && AttmComp === 'A') {
//         //     return "Error-A: Loss Code must be None and Unknown"

//         // }
//         // //B
//         // else if (nibrsCode === '280' && AttmComp === 'C' && lossCode != 'None' && lossCode != "RECD") {
//         //     return 'Error: Loss Code must be None or Recovered'

//         // }
//         // //C
//         // else if (noneReacStolCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "None" && lossCode != "RECD" && lossCode != "STOL") {
//         //     return 'Error: Loss Code must be either None , Recovered or Stolen'

//         // }
//         //E/K
//         if (nibrsCode === '200' && AttmComp === 'C' && lossCode != "BURN") {
//             return 'Error: Loss Code must be "Burned Property" with offence Code "200"'

//         }
//         //D
//         // else if (burnOrSizedCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != 'None' && lossCode != "CON") {
//         //     return 'Error: Loss Code must be either "None" or "Seized Property""'

//         // }
//         //F/L
//         else if ((nibrsCode === '250' || nibrsCode === '58B') && AttmComp === 'C' && lossCode != "FORG") {
//             return 'Error: Loss Code must be either "Counterfeited/Forged"'

//         }
//         //G/M
//         else if (nibrsCode === '290' && AttmComp === 'C' && lossCode != "DAMA") {
//             return 'Error: Loss Code must be "Destroyed/Damaged/Vandalized"'

//         }
//         //H
//         // else if (recoStolenCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "RECD" && lossCode != "STOL") {
//         //     return 'Error: Loss Code must be either Recovered Or Stolen'

//         // }
//         //I
//         else if (sizedLossCodeArr?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "CON") {
//             return 'Error: Loss Code Must be "Seized"'

//         }
//         //N
//         else if (recoverdCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "RECD") {
//             return 'Error: Loss Code Must be "Recovered"'

//         }
//         //J 
//         else if (checkAttOrCompNone(nibrsCode, AttmComp) && lossCode != "None" && lossCode != "Unknown") {
//             console.log(checkAttOrCompNone(nibrsCode, AttmComp))
//             return 'Error: Loss Code Must be "None"'

//         }
//         //o
//         else if (sizedCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "CON") {
//             return 'Error: Loss Code  Must be "Seized"'

//         }
//         //P
//         else if (stolencodeArr?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "STOL") {
//             return 'Error: Loss Code Must be "Stolen"'

//         }
//         //Q
//         else if (checkUnknown(nibrsCode, AttmComp) && lossCode != "Unknown" && lossCode != "None") {
//             return 'Error: Loss Code Must be "Unknown"'

//         }
//         else {
//             return ''
//         }
//     } else {
//         return ''
//     }


//     // if (lossCode) {
//     //     // A
//     //     if (CodeArray?.includes(nibrsCode) && lossCode != 'None' && lossCode != 'Unknown' && AttmComp === 'A') {
//     //         return "Error-A: Loss Code must be None and Unknown"

//     //     }
//     //     //B
//     //     else if (nibrsCode === '280' && AttmComp === 'C' && lossCode != 'None' && lossCode != "RECD") {
//     //         return 'Error: Loss Code must be None or Recovered'

//     //     }
//     //     //C
//     //     else if (noneReacStolCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "None" && lossCode != "RECD" && lossCode != "STOL") {
//     //         return 'Error: Loss Code must be either None , Recovered or Stolen'

//     //     }
//     //     //E/K
//     //      if (nibrsCode === '200' && AttmComp === 'C' && lossCode != "BURN") {
//     //         return 'Error: Loss Code must be "Burned Property" with offence Code "200"'

//     //     }
//     //     //D
//     //     else if (burnOrSizedCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != 'None' && lossCode != "CON") {
//     //         return 'Error: Loss Code must be either "None" or "Seized Property""'

//     //     }
//     //     // F/L
//     //     else if ((nibrsCode === '250' || nibrsCode === '58B') && AttmComp === 'C' && lossCode != "FORG" && lossCode != "RECD" && lossCode != "CON") {
//     //         return 'Error: Loss Code must be either "Counterfeited/Forged, Recovered,Seized"'

//     //     }
//     //     // G/M
//     //     else if (nibrsCode === '290' && AttmComp === 'C' && lossCode != "DAMA") {
//     //         return 'Error: Loss Code must be "Destroyed/Damaged/Vandalized"'

//     //     }
//     //     // H
//     //     else if (recoStolenCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "RECD" && lossCode != "STOL") {
//     //         return 'Error: Loss Code must be either Recovered Or Stolen'

//     //     }
//     //     //I
//     //     else if (sizedLossCodeArr?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "CON") {
//     //         return 'Error: Loss Code Must be "Seized"'

//     //     }
//     //     //N
//     //     else if (recoverdCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "RECD") {
//     //         return 'Error: Loss Code Must be "Recovered"'

//     //     }
//     //     //J
//     //     else if (noneCodeArray?.includes(nibrsCode) && lossCode != "None") {
//     //         return 'Error: Loss Code Must be "None"'

//     //     }
//     //     //o
//     //     else if (sizedCodeArray?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "CON") {
//     //         return 'Error: Loss Code  Must be "Seized"'

//     //     }
//     //     //P
//     //     else if (stolencodeArr?.includes(nibrsCode) && AttmComp === 'C' && lossCode != "STOL") {
//     //         return 'Error: Loss Code Must be "Stolen"'

//     //     } else {
//     //         return ''
//     //     }
//     // } else {
//     //     return ''
//     // }
// }

const checkAttOrCompNone = (nibrs, AttComp) => {
    const AttArray = ['200', '250', '290', '270', '210', '26A', '26B', '26C', '26D', '26E', '26F', '26G', '39A', '39B', '39C', '39D', '23A', '23B', '23C', '23D', '23E', '23F', '23G', '23H', '240', '120', '26H', '58B',]

    const CompArr = ['510', '220', '35A', '35B', '100', '280', '521', '522', '526', '58A', '61A', '61B', '620']

    if ((AttArray?.includes(nibrs) && AttComp === 'A') || (CompArr?.includes(nibrs))) {
        return true
    } else {
        return false
    }
}

const checkUnknown = (nibrs, AttComp) => {
    const compArr = ['510', '100', '220']
    const AttArr = ['200', '250', '290', '35A', '35B', '270', '210', '26A', '26B', '26C', '26D', '26E', '26F', '26G', '39A', '39B', '39C', '39D', '23A', '23B', '23C', '23D', '23E', '23F', '23G', '23H', '240', '120', '280', '26H', '521', '522', '526', '58A', '58B', '61A', '61B', '620',
    ]

    if ((AttArr?.includes(nibrs) && AttComp === 'A')) {
        return true
    } else if (compArr?.includes(nibrs)) {
        return true
    } else {
        return false
    }

}

export const check_Category_Nibrs = (nibrsCodeArr, propRecType, propCategoryCode, type = 'Color') => {
    // console.group()
    // console.log("🚀 ~ propCategoryCode:", propCategoryCode);
    // console.log("🚀 ~ propRecType:", propRecType);
    // console.log("🚀 ~ nibrsCodeArr:", nibrsCodeArr);
    // console.groupEnd()
    if (propCategoryCode) {
        if (propCategoryCode === '11' && propRecType === '6' && nibrsCodeArr.includes('35A')) {
            return type === 'Color' ? NibrsErrorStyle(true) : <ErrorTooltip ErrorStr={'Property Description Code Cannot = 11 For Property Loss Type=6 and Offense = 35A'} />
        } else if (propCategoryCode === '10' && propRecType === '5' && nibrsCodeArr.includes('35B')) {
            return type === 'Color' ? NibrsErrorStyle(true) : <ErrorTooltip ErrorStr={' Property Description Code Cannot = 10 For Property Loss Type=6 and Offense = 35B'} />
        } else {
            return false
        }
    } else {
        return false
    }
}