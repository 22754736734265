import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { AddDeleteUpadate, fetchPostData } from '../../../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { Comman_changeArrayFormat, threeColArray } from '../../../../../../Common/ChangeArrayFormat';
import { toastifySuccess } from '../../../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../../../Common/DeleteModal';
import { RequiredFieldIncident } from '../../../../../Utility/Personnel/Validation';
import { AgencyContext } from '../../../../../../../Context/Agency/Index';
import { components } from "react-select";


const AssaultType = (props) => {

  const { victimID, nameID, loginPinID, offenceCodesArr } = props

  const { get_NameVictim_Count, } = useContext(AgencyContext);

  const SelectedValue = useRef();
  const [assaultDrp, setAssaultDrp] = useState();
  const [assultData, setAssultData] = useState();
  const [assultID, setAssultID] = useState();
  const [nameEventAssaultID, setNameEventAssaultID] = useState();
  const [typeOfSecurityEditVal, setTypeOfSecurityEditVal] = useState();

  const [value, setValue] = useState({
    'AssaultID': '',
    'NameID': nameID,
    'VictimID': victimID,
    'CreatedByUserFK': loginPinID,
  })

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const [errors, setErrors] = useState({ 'DropError': '', });

  const [multiSelected, setMultiSelected] = useState({
    AssaultID: null,
  })

  const Reset = () => {
    setValue({ ...value, 'AssaultID': '', });
    setErrors({ ...errors, 'DropError': '', });
  }

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.AssaultID)) {
      setErrors(prevValues => { return { ...prevValues, ['DropError']: RequiredFieldIncident(value.AssaultID) } })
    }
  }

  const { DropError } = errors

  useEffect(() => {
    get_Assults_Drp(victimID);
  }, [victimID])

  useEffect(() => {
    if (victimID) { get_Assults_Data(); }
  }, [victimID])

  const get_Assults_Data = () => {
    const val = { 'VictimID': victimID, }
    fetchPostData('VictimAssaultType/GetData_VictimAssaultType', val).then((res) => {
      if (res) {
        setTypeOfSecurityEditVal(Comman_changeArrayFormat(res, 'NameEventAssaultID', 'NameID', 'PretendToBeID', 'AssaultID', 'Assault_Description'));
      } else {
        setTypeOfSecurityEditVal([]);
      }
    })
  }

  const get_Assults_Drp = (victimID) => {
    const val = { 'VictimID': victimID, }
    fetchPostData('VictimAssaultType/GetData_InsertVictimAssaultType', val).then((data) => {
      if (data) {
       
        setAssaultDrp(threeColArray(data, 'AssaultTypeID', 'Description'))
      } else {
        setAssaultDrp([])
      }
    })
  }

  useEffect(() => {
    if (typeOfSecurityEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['AssaultID']: typeOfSecurityEditVal } }) }
  }, [typeOfSecurityEditVal])

  // const Add_Victim_Offense = () => {
  //   AddDeleteUpadate('VictimAssaultType/Insert_VictimAssaultType', value).then((res) => {
  //     if (res) {
  //       toastifySuccess(res.Message)
  //       get_Assults_Data(victimID);
  //       get_Assults_Drp(victimID);
  //       get_NameVictim_Count(victimID)
  //       onClear()
  //       Reset();
  //       setErrors({
  //         'DropError': '',
  //       });
  //     }
  //   })
  // }

  // const DeleteAssults = () => {
  //   const val = {
  //     'NameEventAssaultID': nameEventAssaultID,
  //     'DeletedByUserFK': loginPinID,
  //   }
  //   AddDeleteUpadate('VictimAssaultType/Delete_VictimAssaultType', val).then((res) => {
  //     if (res) {
  //       toastifySuccess(res.Message);
  //       get_Assults_Drp(victimID);
  //       get_NameVictim_Count(victimID)
  //       get_Assults_Data(victimID);
  //     } else console.log("Somthing Wrong");
  //   })
  // }

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({ ...value, [name]: e.value })
    } else {
      setValue({ ...value, [name]: null })
    }
  }

  const onClear = () => {
    SelectedValue?.current?.clearValue();
  };

  const columns = [
    {
      name: 'Description',
      selector: (row) => row.Assault_Description,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, left: '15px' }}>Delete</p>,
      cell: row =>

        <Link to={`#`} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={() => { setNameEventAssaultID(row.NameEventAssaultID); }} data-toggle="modal" data-target="#DeleteModal">
          <i className="fa fa-trash"></i>
        </Link>
    }
  ]

  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  const assault = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      AssaultID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < typeOfSecurityEditVal?.length) {
      let missing = null;
      let i = typeOfSecurityEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(typeOfSecurityEditVal[--i])) ? missing : typeOfSecurityEditVal[i];
      }
      DelSertBasicInfo(missing.value, 'NameEventAssaultID', 'VictimAssaultType/Delete_VictimAssaultType')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'AssaultID', 'VictimAssaultType/Insert_VictimAssaultType')
    }
  }

  const InSertBasicInfo = (id, col1, url) => {
    const val = {
      'NameID': nameID,
      'VictimID': victimID,
      [col1]: id,
      'CreatedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {

        toastifySuccess(res.Message);
        get_Assults_Drp(victimID)
        // get_IncidentTab_Count(incidentID);
        col1 === 'AssaultID' && get_Assults_Data();
      } else {
        console.log("Somthing Wrong");
      }
    })
  }

  const DelSertBasicInfo = (NameEventAssaultID, col1, url) => {
    const val = {
      [col1]: NameEventAssaultID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_Assults_Drp(victimID)
        // get_IncidentTab_Count(incidentID);
        col1 === 'NameEventAssaultID' && get_Assults_Data()
      } else {
        console.log("res");
      }
    })
  }

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      minHeight: 60,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>
      <div className="col-12 " id='display-not-form'>
        <div className="col-12 col-md-12 mt-2 pt-1 p-0" >
          <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center">
            <p className="p-0 m-0">Assault Type</p>
          </div>
        </div>
        <div className="row">
          <div className="col-2 col-md-2 col-lg-1 mt-3">
            <label htmlFor="" className='label-name '>
              Assault Type
              {errors.DropError !== 'true' ? (
                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</span>
              ) : null}
            </label>
          </div>
          <div className="col-4 col-md-4 col-lg-4  mt-2" >
            <Select
              name='AssaultID'
              isClearable
              options={assaultDrp}
              closeMenuOnSelect={false}
              // onChange={(e) => { ChangeDropDown(e, 'AssaultID'); }}
              placeholder="Select.."
              ref={SelectedValue}
              className="basic-multi-select"
              isMulti
              styles={customStylesWithOutColor}
              components={{ MultiValue, }}
              onChange={(e) => assault(e)}
              value={multiSelected.AssaultID}
            />
          </div>
        </div>
      </div>
      {/* <div className="col-12">
        <DataTable
          columns={columns}
          data={assultData}
          dense
          pagination
          paginationPerPage={'3'}
          paginationRowsPerPageOptions={[3]}
          selectableRowsHighlight
          highlightOnHover
          noDataComponent={"There are no data to display"}
        />
      </div> */}
      {/* <DeletePopUpModal func={DeleteAssults} /> */}
    </>
  )
}

export default AssaultType