import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { fetchPostData, AddDeleteUpadate, ScreenPermision, fetchData } from '../../../../hooks/Api';
import { Decrypt_Id_Name, DecryptedList, base64ToString, filterPassed2, filterPassedDateTime, filterPassedDateTimeZone, filterPassedTime, getShowingDateText, getShowingMonthDateYear, tableCustomStyles } from '../../../../Common/Utility';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import Loader from '../../../../Common/Loader';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import DatePicker from "react-datepicker";
import Select from "react-select";
import { RequiredFieldIncident, SpaceCheck, checkDateIsAfter } from '../../../Utility/Personnel/Validation';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import ChangesModal from '../../../../Common/ChangesModal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { get_AgencyOfficer_Data } from '../../../../../redux/actions/DropDownsData';
import { Calendar } from 'primereact/calendar';
import { NameValidationCharacter } from '../../../Agency/AgencyValidation/validators';
const Pin = (props) => {

  const { incidentReportedDate } = props
  const { get_IncidentTab_Count, setChangesStatus, changesStatus,GetDataTimeZone, datezone } = useContext(AgencyContext);

  const dispatch = useDispatch()
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  // const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);

  const useQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const query = useQuery();
  var IncID = query?.get("IncId");
  if (!IncID) IncID = 0;
  else IncID = parseInt(base64ToString(IncID));


  const [activityDate, setActivityDate] = useState(new Date());
  const [pindata, setPindata] = useState();
  const [status, setStatus] = useState(false);
  const [pinActivityID, setPinActivityID] = useState();
  const [incidentPinActivityID, setIncidentPinActivityID] = useState('');
  const [loder, setLoder] = useState(false);
  const [shiftList, setShiftList] = useState();
  const [editval, setEditval] = useState();
  const [clickedRow, setClickedRow] = useState(null);
  const [pinActivityStatusDrpVal, setPinActivityStatusDrpVal] = useState([]);
  const [pinActivityRoleIdDrpVal, setPinActivityRoleIdDrpVal] = useState([]);
  const [agencyOfficerDrpData, setAgencyOfficerDrpData] = useState([]);
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [incidentID, setIncidentID] = useState('');
  const [loginPinID, setLoginPinID] = useState('');
  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);
  const [statesChangeStatus, setStatesChangeStatus] = useState(false);

  // Check All Field Format is True Then Submit 
  const [value, setValue] = useState({
    'ActivityDateTime': '', 'PINActivityStatusID': null, 'PINActivityRoleID': null, 'PINActivityRoleName': '',
    'OfficerPINID': null, 'ShiftID': null, 'ShiftName': '', 'ResourceNumber': '', 'IncidentId': '',
    'CreatedByUserFK': '', 'ModifiedByUserFK': '', 'IncidentPINActivityID': null,
  });

  const [errors, setErrors] = useState({
    'ActivityDateTimeError': '', 'ActivityRoleStatusError': '', 'OfficerPinError': '', 'ResourceNumberError': '',
  })

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(parseInt(localStoreData?.PINID)); getScreenPermision(localStoreData?.AgencyID, localStoreData?.PINID);
      get_Pin_Drp_Data(localStoreData?.AgencyID)
      // if (agencyOfficerDrpData?.length === 0) { dispatch(get_AgencyOfficer_Data(localStoreData?.AgencyID)) }
      GetDataTimeZone(localStoreData?.AgencyID);
    }
  }, [localStoreData]);

  useEffect(() => {
    if (IncID) {
      setValue({
        ...value,
        'IncidentId': IncID, 'CreatedByUserFK': '', 'ModifiedByUserFK': '', 'IncidentPINActivityID': null,
        'ActivityDateTime': '', 'PINActivityStatusID': null, 'PINActivityRoleID': null, 'PINActivityRoleName': '',
        'OfficerPINID': null, 'ShiftID': null, 'ShiftName': '', 'ResourceNumber': '',
      });
      setIncidentID(IncID); get_Pin_Data(IncID); setLoder(true);
      // get_IncidentTab_Count(IncID);
    }
  }, [IncID]);

  ///-------GET SHIFT-----///
  const get_Pin_Drp_Data = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('PINActivity/GetPINActivityDropDown', val).then((data) => {
      if (data) {
        setShiftList(changeArrayFormat(data[0]?.Shift, 'shiftListVal'));
        setAgencyOfficerDrpData(changeArrayFormat(data[0]?.Officer, 'HeadOfAgencyVal'));
        setPinActivityStatusDrpVal(Comman_changeArrayFormat(data[0]?.PinActivity, 'ActivityTypeID', 'Description'));
        setPinActivityRoleIdDrpVal(changeArrayFormat(data[0]?.ActivityRole, 'PINActivityRoleVal'));
      } else {
        setShiftList([]); setPinActivityStatusDrpVal([]); setPinActivityRoleIdDrpVal([])
      }
    })
  };

  const get_Head_Of_Agency = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
      if (data) {
        // setHeadOfAgency(changeArrayFormat(data, 'HeadOfAgencyVal'));
      }
      else {
        // setHeadOfAgency([])
      }
    })
  };

  const get_Shift = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('MasterPersonnel/GetData_Shift', val).then((data) => {
      if (data) {
        setShiftList(changeArrayFormat(data, 'shiftListVal'));
      }
      else {
        setShiftList([])
      }
    })
  };

  //------------PinActivityStatus----------------//
  const get_pin_Activity_Status = (loginAgencyID) => {
    const val = { 'AgencyID': loginAgencyID }
    fetchPostData('PINActivity/GetData_PINActivityType', val).then((data) => {

      // setPinActivityStatusDrpVal(Comman_changeArrayFormat(data, 'ActivityTypeID', 'Description'));
    })
  }

  //-----------PinActivityRole------------------// 
  const get_pin_Activity_Role = (loginAgencyID) => {
    const val = { 'AgencyID': loginAgencyID }
    fetchPostData('PINActivity/GetData_ActivityRole', val).then((data) => {

      // setPinActivityRoleIdDrpVal(changeArrayFormat(data, 'PINActivityRoleVal'));
    })
  }

  const get_Pin_Data = (incidentID) => {
    const val = { 'IncidentId': incidentID, }
    fetchPostData('PINActivity/GetData_PinActivity', val).then((res) => {
      if (res) {

        setPindata(res); setLoder(true);
      } else {
        setPindata([]); setLoder(true);
      }
    })
  }

  const GetSingleData = (pinActivityID) => {
    const val = { 'IncidentPINActivityID': pinActivityID }
    fetchPostData('PINActivity/GetSingleData_PINActivity', val)
      .then((res) => {
        if (res?.length > 0) { setEditval(res); setStatus(true); }
        else { setEditval() }
      })
  }

  useEffect(() => {
    if (incidentID) {
      setValue(pre => {
        return {
          ...pre,
          'IncidentPINActivityID': pinActivityID,
          'ActivityDateTime': editval[0]?.ActivityDateTime ? getShowingDateText(editval[0]?.ActivityDateTime) : null,
          'ResourceNumber': editval[0]?.ResourceNumber,
          'PINActivityStatusID': editval[0]?.PINActivityStatusID, 'PINActivityRoleID': editval[0]?.PINActivityRoleID,
          'OfficerPINID': editval[0]?.OfficerPINID, 'ShiftID': editval[0]?.ShiftID,
          'ModifiedByUserFK': loginPinID,
        }
      })
      setActivityDate(editval[0]?.ActivityDateTime ? new Date(editval[0]?.ActivityDateTime) : null);
    } else {
      setValue({
        ...value,
        'PINActivityStatusID': null, 'IncidentId': IncID, 'CreatedByUserFK': loginPinID, 'PINActivityRoleID': null, 'ShiftID': null, 'ModifiedByUserFK': '', 'ResourceNumber': '', 'ActivityDateTime': '',
      });
      // setActivityDate(null);
    }
  }, [editval,])

  const check_Validation_Error = (e) => {
    const ActivityRoleStatusErr = RequiredFieldIncident(value.PINActivityRoleID);
    const ActivityDateTimeErr = RequiredFieldIncident(value.ActivityDateTime);
    const OfficerPinErr = RequiredFieldIncident(value.OfficerPINID);
    // const ResourceNumberErr = SpaceCheck(value.ResourceNumber);
    const ResourceNumberErr = NameValidationCharacter(value.ResourceNumber);

    setErrors(prevValues => {
      return {
        ...prevValues,
        ['ActivityRoleStatusError']: ActivityRoleStatusErr || prevValues['ActivityRoleStatusError'],
        ['ActivityDateTimeError']: ActivityDateTimeErr || prevValues['ActivityDateTimeError'],
        ['OfficerPinError']: OfficerPinErr || prevValues['OfficerPinError'],
        ['ResourceNumberError']: ResourceNumberErr || prevValues['ResourceNumberError'],
      }
    });
  }

  // Check All Field Format is True Then Submit 
  const { ActivityDateTimeError, ActivityRoleStatusError, OfficerPinError, ResourceNumberError } = errors

  useEffect(() => {
    if (ActivityDateTimeError === 'true' && ActivityRoleStatusError === 'true' && OfficerPinError === 'true' && ResourceNumberError === 'true') {
      if (status) { update_Activity() }
      else { Add_Type() }
    }
  }, [ActivityDateTimeError, ActivityRoleStatusError, OfficerPinError, ResourceNumberError])

  const Add_Type = (e) => {
    const { ActivityDateTime, PINActivityStatusID, PINActivityRoleID, PINActivityRoleName,
      OfficerPINID, ShiftID, ShiftName, ResourceNumber, IncidentId,
      CreatedByUserFK, ModifiedByUserFK, IncidentPINActivityID } = value;
    const val = {
      'ActivityDateTime': ActivityDateTime, 'PINActivityStatusID': PINActivityStatusID, 'PINActivityRoleID': PINActivityRoleID,
      'PINActivityRoleName': PINActivityRoleName, 'OfficerPINID': OfficerPINID, 'ShiftID': ShiftID, 'ShiftName': ShiftID, 'ResourceNumber': ResourceNumber,
      'IncidentId': IncidentId, 'CreatedByUserFK': loginPinID, 'ModifiedByUserFK': '', 'IncidentPINActivityID': IncidentPINActivityID,
    }
    const matches = pindata.filter(item => item.OfficerPINID == val.OfficerPINID && item.PINActivityStatusID == val.PINActivityStatusID && item.PINActivityRoleID == val.PINActivityRoleID);

    if (matches.length > 0) {
      toastifyError('Activity Already Exist'); setErrors({ ...errors, ['ActivityDateTimeError']: '', });
    } else {
      AddDeleteUpadate('PINActivity/InsertPINActiivty', val).then((res) => {
        if (res?.success && res?.Code === 200) {
          const parsedData = JSON.parse(res.data);
          const message = parsedData.Table[0].Message;
          toastifySuccess(message);
          get_Pin_Data(incidentID); get_IncidentTab_Count(incidentID); setToReset();
          setErrors({ ...errors, ['ActivityDateTimeError']: '', });
          setChangesStatus(false); setStatesChangeStatus(false);
        }
      })
    }
  }

  const update_Activity = () => {
    const { ActivityDateTime, PINActivityStatusID, PINActivityRoleID, PINActivityRoleName,
      OfficerPINID, ShiftID, ShiftName, ResourceNumber, IncidentId,
      CreatedByUserFK, ModifiedByUserFK, IncidentPINActivityID } = value;
    const val = {
      'ActivityDateTime': ActivityDateTime, 'PINActivityStatusID': PINActivityStatusID, 'PINActivityRoleID': PINActivityRoleID,
      'PINActivityRoleName': PINActivityRoleName, 'OfficerPINID': OfficerPINID, 'ShiftID': ShiftID, 'ShiftName': ShiftID, 'ResourceNumber': ResourceNumber,
      'IncidentId': IncidentId, 'CreatedByUserFK': loginPinID, 'ModifiedByUserFK': ModifiedByUserFK, 'IncidentPINActivityID': IncidentPINActivityID,
    }
    const result = pindata?.filter(item => {
      if (item?.IncidentPINActivityID != value['IncidentPINActivityID']) {
        if (item?.OfficerPINID == val?.OfficerPINID && item?.PINActivityStatusID == val?.PINActivityStatusID && item?.PINActivityRoleID == val?.PINActivityRoleID) {
          return true;
        } else {
          return false;
        }
      }
    });
    if (result?.length > 0) {
      toastifyError('Activity Already Exist'); setErrors({ ...errors, ['ActivityDateTimeError']: '', });
    } else {
      AddDeleteUpadate('PINActivity/UpdatePinActivity', val).then((res) => {
        if (res?.success && res?.Code === 200) {
          const parsedData = JSON.parse(res.data);
          const message = parsedData.Table[0].Message;
          toastifySuccess(message);
          get_Pin_Data(incidentID); setStatus(true); setToReset();
          setChangesStatus(false); setStatesChangeStatus(false);
        }
      })
    }
  }

  const getScreenPermision = (LoginAgencyID, PinID) => {
    ScreenPermision("I028", LoginAgencyID, PinID).then(res => {
      if (res) {
        setEffectiveScreenPermission(res)
      } else {
        setEffectiveScreenPermission([])
      }
    });
  }

  const reset = () => {
    setValue({
      ...value,
      'PINActivityStatusID': null, 'PINActivityRoleID': null, 'OfficerPINID': null, 'ModifiedByUserFK': '', 'ResourceNumber': '', 'ShiftID': null, 'ActivityDateTime': '',
    });
    setActivityDate(null); setStatesChangeStatus(false);
    setErrors({ ...errors, 'ActivityDateTimeError': '', 'ActivityRoleStatusError': '', 'OfficerPinError': '', 'ResourceNumberError': '', });
  }

  const startRef = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const columns = [
    {
      // width: '150px',
      name: 'Date/Time',
      selector: (row) => row.ActivityDateTime ? getShowingDateText(row.ActivityDateTime) : '',
      sortable: true
    },
    {
      // width: '200px',
      name: 'Activity Details',
      selector: (row) => row.ActivityStatus ? row.ActivityStatus : '',
      sortable: true
    },
    {
      name: 'Role',
      selector: (row) => row.ActivityRole ? row.ActivityRole : '',
      sortable: true
    },
    {
      name: 'Officer',
      selector: (row) => row.OfficerName ? row.OfficerName : '',
      format: (row) => (
        <>{row?.OfficerName ? row?.OfficerName.substring(0, 30) : ''}{row?.OfficerName?.length > 40 ? '  . . .' : null} </>
      ),
      sortable: true
    },
    {
      name: 'Module',
      selector: (row) => row.ResourceNumber ? row.ResourceNumber : '',
      format: (row) => (
        <>{row?.ResourceNumber ? row?.ResourceNumber.substring(0, 30) : ''}{row?.ResourceNumber?.length > 40 ? '  . . .' : null} </>
      ),
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 10 }}>Delete</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', top: 4, right: 10 }}>
          {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
              <span onClick={() => { setIncidentPinActivityID(row.IncidentPINActivityID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
              : <></>
              : <span onClick={() => { setIncidentPinActivityID(row.IncidentPINActivityID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
          }
        </div>
    }
  ]

  const handleChange = (e) => {
    setChangesStatus(true); setStatesChangeStatus(true)
    setValue({
      ...value,
      [e.target.name]: e.target.value
    })
  }

  const set_Edit_Value = (row) => {
    reset()
    GetSingleData(row.IncidentPINActivityID);
    setPinActivityID(row.IncidentPINActivityID);
    // setChangesStatus(true);
    setErrors({
      ...errors,
      'ActivityDateTimeError': '', 'ActivityRoleStatusError': '', 'OfficerPinError': '', 'ResourceNumberError': '',
    });
  }

  const DeletePin = () => {
    const val = { 'IncidentPINActivityID': incidentPinActivityID, 'DeletedByUserFK': loginPinID, }
    AddDeleteUpadate('PINActivity/DeletePinActivity', val).then((res) => {
      if (res) {
        const parsedData = JSON.parse(res.data);
        const message = parsedData.Table[0].Message;
        toastifySuccess(message);
        get_Pin_Data(incidentID);
        get_IncidentTab_Count(incidentID);
        setToReset(); setChangesStatus(false);
      } else { console.log("Somthing Wrong"); }
    })
  }

  const setToReset = (e) => {
    setClickedRow(null); setStatus(false); reset(); setErrors({ ...errors, 'ActivityDateTimeError': '', 'ActivityRoleStatusError': '', 'OfficerPinError': '', 'ResourceNumberError': '', });
    setChangesStatus(false); setStatesChangeStatus(false);
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  const ChangeDropDown = (e, name) => {
    setStatesChangeStatus(true)
    if (e) {
      setChangesStatus(true)
      setValue({
        ...value,
        [name]: e.value
      })
    } else {
      setChangesStatus(true)
      setValue({
        ...value,
        [name]: null
      })
    }
  }

  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    }
  ];

  const reportedTime = new Date(incidentReportedDate);
  let reportDate = reportedTime.getDate();

  // function checkObjectMatch(obj, arr) {
  //   const matches = arr.filter(item => item.name === obj.name && item.head === obj.head);
  //   return matches.length > 0;
  // }

  // const obj = { name: 'Alice', head: '12', ravi: 'roror' };

  // const arr = [
  //   { id: 1, name: 'Alice', head: '11' },
  //   { id: 2, name: 'Bob', head: '22' },
  //   { id: 3, name: 'Alice', head: '33' }
  // ];

  const filterTimeForDateZone = (time, datezone) => {
    const zoneDate = new Date(datezone);
    const zoneHours = zoneDate.getHours();
    const zoneMinutes = zoneDate.getMinutes();
  
    const timeHours = time.getHours();
    const timeMinutes = time.getMinutes();
    if (timeHours > zoneHours || (timeHours === zoneHours && timeMinutes > zoneMinutes)) {
      return false;
    }
  
    return true;
  };
  



  return (
    <>
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-md-4 col-lg-1 mt-2 pt-2">
            <label htmlFor="" className='new-label'>Date/Time{errors.ActivityDateTimeError !== 'true' ? (
              <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.ActivityDateTimeError}</p>
            ) : null}</label>
          </div>
          <div className="col-7 col-md-7 col-lg-3 mt-1 ">
            <DatePicker
              ref={startRef}
              onKeyDown={(e) => {
                if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                  e.preventDefault();
                } else {
                  onKeyDown(e);
                }
              }
              }
              id='ActivityDateTime'
              name='ActivityDateTime'
              className='requiredColor'
              dateFormat="MM/dd/yyyy HH:mm"
              onChange={(date) => {
                if (date) {
                  setStatesChangeStatus(true); setChangesStatus(true)
                  if (date >= new Date()) {
                    setValue({ ...value, ['ActivityDateTime']: new Date() ? getShowingMonthDateYear(new Date()) : null })
                    setActivityDate(new Date());
                  } else if (date <= new Date(incidentReportedDate)) {
                    setValue({ ...value, ['ActivityDateTime']: incidentReportedDate ? getShowingMonthDateYear(incidentReportedDate) : null })
                    setActivityDate(incidentReportedDate);
                  } else {
                    setActivityDate(date);
                    setValue({ ...value, ['ActivityDateTime']: date ? getShowingMonthDateYear(date) : null })
                  }
                } else {
                  setActivityDate(null);
                  setValue({ ...value, ['ActivityDateTime']: null })
                  setStatesChangeStatus(true);
                }
              }}
              placeholderText={value?.ActivityDateTime ? value?.ActivityDateTime : 'Select...'}
              // selected={activityDate}
              selected={value?.ActivityDateTime ? new Date(value?.ActivityDateTime) : null}
              isClearable={value?.ActivityDateTime ? true : false}
              timeInputLabel
              showTimeSelect
              timeIntervals={1}
              timeCaption="Time"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={new Date(incidentReportedDate)}
              maxDate={new Date(datezone)}
              // showDisabledMonthNavigation

              filterTime={(time) => filterPassedDateTimeZone(time, activityDate, incidentReportedDate ,datezone)}
              // filterTime={(time) => filterTimeForDateZone(time, datezone)}
              autoComplete='off'
              timeFormat="HH:mm "
              is24Hour
            />

          </div>
          <div className="col-4 col-md-4 col-lg-1 mt-2 pt-1">
            <label htmlFor="" className='new-label'>Officer {errors.OfficerPinError !== 'true' ? (
              <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.OfficerPinError}</p>
            ) : null}</label>
          </div>
          <div className="col-7 col-md-7 col-lg-3 mt-2 ">
            <Select
              name='OfficerPINID'
              styles={colourStyles}
              value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.OfficerPINID)}
              isClearable
              options={agencyOfficerDrpData}
              onChange={(e) => ChangeDropDown(e, 'OfficerPINID')}
              placeholder="Select..."
            />
          </div>
          <div className="col-4 col-md-4 col-lg-1 mt-2 pt-1">
            <label htmlFor="" className='new-label'>Role{errors.ActivityRoleStatusError !== 'true' ? (
              <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.ActivityRoleStatusError}</p>
            ) : null}</label>
          </div>
          <div className="col-7 col-md-7 col-lg-3 mt-2 ">
            <Select
              name='PINActivityRoleID'
              styles={colourStyles}
              isClearable
              value={pinActivityRoleIdDrpVal?.filter((obj) => obj.value === value?.PINActivityRoleID)}
              options={pinActivityRoleIdDrpVal}
              onChange={(e) => ChangeDropDown(e, 'PINActivityRoleID')}
              placeholder="Select.."
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-md-4 col-lg-1 mt-2 pt-1 px-0">
            <label htmlFor="" className='new-label px-0'>Activity&nbsp;Details</label>
          </div>
          <div className="col-7 col-md-7 col-lg-3 mt-2 mb-1">
            <Select
              name='PINActivityStatusID'
              styles={customStylesWithOutColor}
              isClearable
              value={pinActivityStatusDrpVal?.filter((obj) => obj.value === value?.PINActivityStatusID)}
              options={pinActivityStatusDrpVal}
              onChange={(e) => ChangeDropDown(e, 'PINActivityStatusID')}
              placeholder="Select.."
            />
          </div>
          <div className="col-4 col-md-4 col-lg-1 mt-2 pt-2">
            <label htmlFor="" className='new-label'>Module{errors.ResourceNumberError !== 'true' ? (
              <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.ResourceNumberError}</p>
            ) : null}</label>
          </div>
          <div className="col-7 col-md-7 col-lg-3 mt-2 pt-1 text-field ">
            <input type="text" name='ResourceNumber' maxLength={50} value={value.ResourceNumber} onChange={handleChange} required autoComplete='off' />
          </div>

          <div className="col-4 col-md-4 col-lg-1 mt-2 pt-2">
            <label htmlFor="" className='new-label'>Shift</label>
          </div>
          <div className="col-7 col-md-7 col-lg-3 mt-2 pt-1  mb-1">
            <Select
              name='ShiftName'
              styles={customStylesWithOutColor}
              isClearable
              value={shiftList?.filter((obj) => obj.value === value?.ShiftID)}
              options={shiftList}
              onChange={(e) => ChangeDropDown(e, 'ShiftID')}
              placeholder="Select.."
            />
          </div>
        </div>
      </div>
      <div className="btn-box text-right mt-2 mr-1 ">
        <button type="button" className="btn btn-sm btn-success mr-1 mb-2"
          data-toggle={changesStatus ? "modal" : "pill"} data-target={changesStatus ? "#SaveModal" : ''}
          onClick={() => { if (!changesStatus) { setToReset(); } }}
        >New</button>
        {
          status ?
            effectiveScreenPermission ?
              effectiveScreenPermission[0]?.Changeok ?
                <button type="button" disabled={!statesChangeStatus} onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1 mb-2">Update</button>
                :
                <>
                </>
              :
              <button type="button" disabled={!statesChangeStatus} onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1 mb-2">Update</button>
            :
            effectiveScreenPermission ?
              effectiveScreenPermission[0]?.AddOK ?
                <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1 mb-2">Save</button>
                :
                <>
                </>
              :
              <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1 mb-2">Save</button>
        }
      </div>
      <div className="col-md-12 mt-2 px-0 container-fluid" >
        {
          loder ?
            <DataTable
              showHeader={true}
              persistTableHead={true}
              dense
              // selectableRowsHighlight
              columns={columns}
              data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? pindata : [] : pindata}
              highlightOnHover
              conditionalRowStyles={conditionalRowStyles}
              customStyles={tableCustomStyles}
              onRowClicked={(row) => {
                set_Edit_Value(row); setClickedRow(row);
              }}
              fixedHeaderScrollHeight='300px'
              fixedHeader
              pagination
              paginationPerPage={'100'}
              paginationRowsPerPageOptions={[100, 150, 200, 500]}
              showPaginationBottom={100}
              noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
            />
            :
            <Loader />
        }
      </div>
      <IdentifyFieldColor />
      <ChangesModal func={check_Validation_Error} setToReset={setToReset} />
      <DeletePopUpModal func={DeletePin} />
    </>
  )
}

export default Pin

export const changeArrayFormat = (data, type) => {

  if (type === 'PINActivityRoleVal') {
    const result = data?.map((sponsor) =>
      ({ value: sponsor.ActivityTypeID, label: sponsor.Description })
    )
    return result
  }
  if (type === 'HeadOfAgencyVal') {
    const result = data?.map((sponsor) =>
      ({ value: sponsor.PINID, label: sponsor.HeadOfAgency })
    )
    return result
  }
  if (type === 'shiftListVal') {
    const result = data?.map((sponsor) =>
      ({ value: sponsor.ShiftId, label: sponsor.ShiftDescription })
    )
    return result
  }
}

export const changeArrayFormat_WithFilter = (data, type, DropDownValue) => {
  if (DropDownValue) {
    if (type === 'PINActivityRoleVal') {
      const result = data?.map((sponsor) =>
        (sponsor.PINActivityRoleID)
      )
      const result2 = DropDownValue?.map((sponsor) => {
        if (sponsor.value === result[0]) {
          return { value: result[0], label: sponsor.label }
        }
      })
      const val = result2.filter(function (element) {
        return element !== undefined;
      });
      return val[0]
    }
    if (type === 'HeadOfAgencyVal') {
      const result = data?.map((sponsor) =>
        (sponsor.OfficerPINID)
      )
      const result2 = DropDownValue?.map((sponsor) => {
        if (sponsor.value === result[0]) {
          return { value: result[0], label: sponsor.label }
        }
      })
      const val = result2.filter(function (element) {
        return element !== undefined;
      });
      return val[0]
    }
    if (type === 'shiftListVal') {
      const result = data?.map((sponsor) =>
        (sponsor.ShiftID)
      )
      const result2 = DropDownValue?.map((sponsor) => {
        if (sponsor.value === result[0]) {
          return { value: result[0], label: sponsor.label }
        }
      })
      const val = result2.filter(function (element) {
        return element !== undefined;
      });
      return val[0]
    }
  }
}