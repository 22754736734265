import React, { useState } from 'react'
import { Link, useLocation } from "react-router-dom";

const ReportSidebar = () => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');

    const [expandList, setExpandList] = useState(null);

    const [plusMinus, setPlusMinus] = useState({
        'Master Table': false,
        'Master Table2': false,
        'Master Table3': false,
        'Master Table5': false,
        'Master Table7': false,
        'Master Table8': false,
    });

    const callReportModules = (type, val) => {
        if (type === 'List') {
            setExpandList(expandList === val ? null : val);
            setPlusMinus(prevState => ({
                ...prevState,
                [val]: !prevState[val],
                ...Object.keys(prevState).reduce((acc, key) => {
                    if (key !== val) acc[key] = false;
                    return acc;
                }, {}),
            }));
        }
    }

    return (
        <>
            <div className="row px-1">
                <div className="col-12 mt-4">
                    <input type="text" className='form-control input-fixed mt-1'
                        placeholder='Search By List ...' />
                </div>
            </div>

            {/* Incident */}
            <li className='mt-2 pt-1'>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus['Master Table']} onClick={() => callReportModules('List', 'Master Table')}>
                    <span className='ml-3'> Incident</span>
                </Link>
                <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table'} className={`${expandList === 'Master Table' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-22px' }}>
                    <li className="ml-3 p-0">
                        <Link to={`/incident-Master?page=incidentReport`} style={{ cursor: 'pointer', background: openPage === 'incidentReport' ? '#EEE' : '' }} >
                            <span>Incident Master Report</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0">
                        <Link to={`/incident-Officer?page=incidentOfficerReport`} style={{ cursor: 'pointer', background: openPage === 'incidentOfficerReport' ? '#EEE' : '' }}>
                            <span >Incident By Officer</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0">
                        <Link to={`/incident-Monthly?page=incidentMonthlyReport`} style={{ cursor: 'pointer', background: openPage === 'incidentMonthlyReport' ? '#EEE' : '' }} >
                            <span >Incidents Monthly</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0" >
                        <Link to={`/incident-DailyEvent?page=incidentDailyEventReport`} style={{ cursor: 'pointer', background: openPage === 'incidentDailyEventReport' ? '#EEE' : '' }}>
                            <span className="">Daily Event Log</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0" >
                        <Link to={`/incident-code?page=incidentcodeReport`} style={{ cursor: 'pointer', background: openPage === 'incidentcodeReport' ? '#EEE' : '' }}>
                            <span className="">Incident Total By Code</span>
                        </Link>
                    </li>
                    {/* <li className="ml-3 p-0">
                        <Link to={`/incident-Monthly?page=incidentMonthlyReport`} style={{ cursor: 'pointer', background: openPage === 'incidentMonthlyReport' ? '#EEE' : '' }} >
                            <span >Incidents Monthly</span>
                        </Link>
                    </li>
                   
               
                    <li className="ml-3 p-0">
                        <Link to={`/incident-media?page=incidentMediaReport`} style={{ cursor: 'pointer', background: openPage === 'incidentMediaReport' ? '#EEE' : '' }}>
                            <span >Media Report</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0">
                        <Link to={`/incident-public?page=incidentPublicReport`} style={{
                            cursor: 'pointer',
                            background: openPage === 'incidentPublicReport' ? '#EEE' : ''
                        }}>
                            <span >Public Report</span>
                        </Link>
                    </li> */}
                </ul>
            </li>

            {/* Name */}
            <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus['Master Table2']} onClick={() => callReportModules('List', 'Master Table2')}>
                    <span className='ml-3'> Name</span>
                </Link>
                <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table2'} className={`${expandList === 'Master Table2' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-22px' }}>
                    <li className="ml-3 p-0">
                        <Link to={`/name-information?page=nameMasterReport`} style={{ cursor: 'pointer', background: openPage === 'nameMasterReport' ? '#EEE' : '' }}>
                            <span>Name Master Report</span>
                        </Link>
                    </li>
                </ul>
            </li>

            {/* Property */}
            <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus['Master Table3']} onClick={() => callReportModules('List', 'Master Table3')}>
                    <span className='ml-3'>Property</span>
                </Link>
                <ul aria-expanded={expandList === 'Master Table3'} className={`${expandList === 'Master Table3' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-22px' }}>
                    <li className="ml-3 p-0">
                        <Link to={`/property-master?page=propertyMasterReport`} style={{ cursor: 'pointer', background: openPage === 'propertyMasterReport' ? '#EEE' : '' }}>
                            <span>Property Master Report</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0">
                        <Link to={`/chaincustody-report?page=chainCustodyReport`} style={{ cursor: 'pointer', background: openPage === 'chainCustodyReport' ? '#EEE' : '' }}>
                            <span >Chain Of Custody Report</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0">
                        <Link to={`/propertyInventory-report?page=propertyInventoryReport`} style={{ cursor: 'pointer', background: openPage === 'propertyInventoryReport' ? '#EEE' : '' }}>
                            <span >Property Inventory Report</span>
                        </Link>
                    </li>

                </ul>
            </li>

            {/* Arrest */}
            <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus['Master Table5']} onClick={() => callReportModules('List', 'Master Table5')}>
                    <span className='ml-3'> Arrest</span>
                </Link>
                <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table5'} className={`${expandList === 'Master Table5' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-22px' }}>
                    <li className="ml-3 p-0">
                        <Link to={`/arrest-master?page=arrestMasterReport`} style={{ cursor: 'pointer', background: openPage === 'arrestMasterReport' ? '#EEE' : '' }}>
                            <span>Arrest Master Report</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0" >
                        <Link to={`/arrest-summary?page=arrestSummaryReport`} style={{ cursor: 'pointer', background: openPage === 'arrestSummaryReport' ? '#EEE' : '' }}>
                            <span className="">Arrest Summary Report</span>
                        </Link>
                    </li>
                    {/* <li className="ml-3 p-0" >
                        <Link to={`/arrest-charge?page=arrestChargeReport`} style={{ cursor: 'pointer', background: openPage === 'arrestChargeReport' ? '#EEE' : '' }}>
                            <span className="">Arrest By Charge</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0">
                        <Link to={`/arrest-incident?page=arrestIncidentReport`} style={{ cursor: 'pointer', background: openPage === 'arrestIncidentReport' ? '#EEE' : '' }}>
                            <span >Arrest By Incident</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0">
                        <Link to={`/arrest-monthly?page=arrestMonthlyReport`} style={{ cursor: 'pointer', background: openPage === 'arrestMonthlyReport' ? '#EEE' : '' }}>
                            <span >Arrest Monthly</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0">
                        <Link to={`/arrest-monthlyCharge?page=arrestMonthlyChargeReport`} style={{ cursor: 'pointer', background: openPage === 'arrestMonthlyChargeReport' ? '#EEE' : '' }}>
                            <span > Arrest Monthly By Charge</span>
                        </Link>
                    </li> */}
                </ul>
            </li>

            {/* Vehicle */}
            <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus['Master Table7']} onClick={() => callReportModules('List', 'Master Table7')}>
                    <span className='ml-3'> Vehicle</span>
                </Link>
                <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table7'} className={`${expandList === 'Master Table7' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-22px' }}>
                    <li className="ml-3 p-0">
                        <Link to={`/vehicle-master?page=vehicleMasterReport`} style={{ cursor: 'pointer', background: openPage === 'vehicleMasterReport' ? '#EEE' : '' }}>
                            <span>Vehicle Master Report</span>
                        </Link>
                    </li>
                </ul>
            </li>

            {/* State */}
            <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus['Master Table8']} onClick={() => callReportModules('List', 'Master Table8')}>
                    <span className='ml-3'> State</span>
                </Link>
                <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table8'} className={`${expandList === 'Master Table8' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-22px' }}>
                    <li className="ml-3 p-0">
                        <Link to={`/state?page=stateReport`} style={{ cursor: 'pointer', background: openPage === 'stateReport' ? '#EEE' : '' }}>
                            <span>State Report</span>
                        </Link>
                    </li>
                </ul>
            </li>
            {/* warrant */}
            {/* <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus4} onClick={() => callReportModules('Table6', 'Master Table6')}><i className="fa fa-lock"></i><span>Warrant</span></Link>
                <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table6'} className={`${expandList === 'Master Table6' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-22px' }}>
                    <li className="ml-3 p-0" >
                        <Link to={`/warrant-expired?page=warrantExpiredReport`} style={{ cursor: 'pointer', background: openPage === 'warrantExpiredReport' ? '#EEE' : '' }}>
                            <span className="">Warrant Expired</span>
                        </Link>
                    </li>
                    <li className="ml-3 p-0">
                        <Link to={`/warrant-monthly?page=warrantMonthlyReport`} style={{ cursor: 'pointer', background: openPage === 'warrantMonthlyReport' ? '#EEE' : '' }}>
                            <span >Warrant Monthly </span>
                        </Link>
                    </li>
                </ul>
            </li> */}

        </>
    );
}

export default ReportSidebar;
