import React, { useCallback, useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Aes256Encrypt, base64ToString, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, tableCustomStyles } from '../../Common/Utility';
import SealUnsealTab from '../../Utility/Tab/SealUnsealTab';
import Select from "react-select";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastifyError, toastifySuccess } from '../../Common/AlertMsg';
import { Comman_changeArrayFormat } from '../../Common/ChangeArrayFormat';
import { AddDelete_Img, fetchPostData } from '../../hooks/Api';
import DatePicker from "react-datepicker";
import SelectBox from '../../Common/SelectBox';
import { AgencyContext } from '../../../Context/Agency/Index';
import { debounce } from 'lodash';
import { get_ScreenPermissions_Data } from '../../../redux/actions/IncidentAction';
import { useDispatch } from 'react-redux';

const SealUnseal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);

    const { datezone, GetDataTimeZone } = useContext(AgencyContext);

    const [loginAgencyID, setloginAgencyID] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [sealUnsealData, setSealUnsealData] = useState([]);
    const [nameSearchValue, setNameSearchValue] = useState([]);
    const [loginPinID, setLoginPinID,] = useState('');
    const [ethinicityDrpData, setEthinicityDrpData] = useState([]);
    const [sexIdDrp, setSexIdDrp] = useState([]);
    const [raceIdDrp, setRaceIdDrp] = useState([]);
    const [logData, setLogData] = useState([]);
    const [dateOfbirth, setdateOfbirth] = useState();
    const [clickedRow, setClickedRow] = useState(null);
    const [masterNameID, setMasterNameID] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [IncidentID, setIncidentID] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState([]);
    const [ChargeID, setChargeID] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [action, setAction] = useState(null);
    const [actionType, setActionType] = useState('');
    const [selectedRowStatus, setSelectedRowStatus] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    var IncID = query?.get("IncId");
    var NameID = query?.get("NameID");
    var MasterNameID = query?.get("MasterNameID");

    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));

    const [value, setValue] = useState({
        'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'DateOfBirth': '', 'SexID': '', 'RaceID': '', 'EthnicityID': '', 'SealUnseal': 1,
        'DateTimeSeal': '',
    })
    const [errors, setErrors] = useState({ 'File_Not_Selected': '', })

    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
            GetDataTimeZone(localStoreData?.AgencyID);
            dispatch(get_ScreenPermissions_Data("U120", localStoreData?.AgencyID, localStoreData?.PINID));
        }
    }, [localStoreData]);

    const getNameSearch = async () => {
        const {
            NameIDNumber, NameIDNumberTo, NameTypeID, NameReasonCodeID, LastName, MiddleName, FirstName, SuffixID, DateOfBirthFrom, DateOfBirthTo, SexID, RaceID, EthnicityID, HairColorID,
            EyeColorID, WeightFrom, WeightTo, SMTTypeID, SMTLocationID, SSN, SMT_Description, IncidentNumber, IncidentNumberTo, ReportedDate, ReportedDateTo, DateOfBirth,
            HeightFrom, HeightTo, AgencyID, PINID, DLNumber, BusinessTypeID, PhoneTypeID, Contact, FaxNumber, RMSCFSCodeID, FBIID, CrimeLocation, OccurredFrom, OccurredFromTo, AgeFrom, AgeTo, AgeUnitID, Local, SBI, FBI, TAX, SPN, Jacket, OCN, State, ComplexionID
        } = value
        const val = {
            'NameIDNumber': NameIDNumber?.trim(), 'NameIDNumberTo': NameIDNumberTo?.trim(), 'NameTypeID': NameTypeID, 'ReasonCodeList': NameReasonCodeID, 'LastName': LastName?.trim(), 'MiddleName': MiddleName?.trim(), 'FirstName': FirstName?.trim(),
            'SuffixID': SuffixID, 'DateOfBirthFrom': DateOfBirthFrom, 'DateOfBirthTo': DateOfBirthTo, 'SexID': SexID, 'RaceID': RaceID, 'EthnicityID': EthnicityID, 'HairColorID': HairColorID,
            'EyeColorID': EyeColorID, 'WeightFrom': WeightFrom, 'WeightTo': WeightTo, 'SMTTypeID': SMTTypeID, 'SMTLocationID': SMTLocationID, 'SSN': SSN, 'SMT_Description': SMT_Description,
            'IncidentNumber': IncidentNumber, 'IncidentNumberTo': IncidentNumberTo, 'ReportedDate': ReportedDate, 'ReportedDateTo': ReportedDateTo, 'DateOfBirth': DateOfBirth,
            'HeightFrom': HeightFrom, 'HeightTo': HeightTo, 'AgencyID': loginAgencyID, 'DLNumber': DLNumber, 'BusinessTypeID': BusinessTypeID, 'PhoneTypeID': PhoneTypeID, 'Contact': Contact, 'FaxNumber': FaxNumber, 'RMSCFSCodeID': RMSCFSCodeID, 'FBIID': FBIID, 'Address': CrimeLocation, 'OccurredFrom': OccurredFrom, 'OccurredFromTo': OccurredFromTo, 'AgeFrom': AgeFrom, 'AgeTo': AgeTo, 'AgeUnitID': AgeUnitID, 'Local': Local, 'SBI': SBI, 'FBI': FBI, 'TAX': TAX, 'SPN': SPN, 'Jacket': Jacket, 'OCN': OCN, 'State': State, 'ComplexionID': ComplexionID,
        }
        if (hasValues(val)) {
            fetchPostData("MasterName/Search_Name", val).then((res) => {
                if (res.length > 0) {
                    setNameSearchValue(res);
                    // Clear();
                } else { setNameSearchValue([]); toastifyError("Data Not Available"); }
            })
        } else { toastifyError("Please Enter Details"); }
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'SSN') {
            let ele = value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) { setValue((prevState) => ({ ...prevState, [name]: match[1] + '-' + match[2] + '-' + match[3], })); }
            } else {
                ele = value.split('-').join('').replace(/\D/g, '');
                setValue((prevState) => ({ ...prevState, [name]: ele, }));
            }
        } else {
            if (event) { setValue((prevState) => ({ ...prevState, [name]: value })); }
            else { setValue((prevState) => ({ ...prevState, [name]: null })); }
        }
    };

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({ ...value, [name]: e.value })
        } else {
            setValue({ ...value, [name]: null });
        }
    };

    useEffect(() => {
        if (loginAgencyID) {
            get_Name_Drp_Data(loginAgencyID)
        }
    }, [loginAgencyID])

    function hasValues(obj) {
        for (let key in obj) {
            if (key != 'AgencyID' && key != 'PINID') {
                if (obj[key]) {
                    return true;
                }
            }
        }
        return false;
    }

    const get_Name_Drp_Data = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('MasterName/GetNameDropDown', val).then((data) => {
            if (data) {
                setEthinicityDrpData(Comman_changeArrayFormat(data[0]?.Ethnicity, 'EthnicityID', 'Description'));
                setSexIdDrp(Comman_changeArrayFormat(data[0]?.Gender, 'SexCodeID', 'Description'));
                setRaceIdDrp(Comman_changeArrayFormat(data[0]?.Race, 'RaceTypeID', 'Description'));
            } else {
                setEthinicityDrpData([]); setSexIdDrp([]); setRaceIdDrp([]);
            }
        })
    };


    const Clear = () => {
        setValue({
            ...value,
            'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'DateOfBirth': '', 'SexID': '', 'RaceID': '', 'EthnicityID': '', 'SealUnseal': 1,
        }); setdateOfbirth(''); setNameSearchValue(''); setLogData(''); setSelectedRowStatus(''); setSelectedRowData(null);
    }

    const onDashboardClose = () => {
        navigate('/dashboard-page');
    }

    const handleRowSelected = (selectedRows) => {
        if (selectedRows.selectedRows.length > 0) {
            const selectedRow = selectedRows.selectedRows[0];
            setMasterNameID(selectedRow.MasterNameID);
            get_LogData(selectedRow.MasterNameID);
        } else {
            setSelectedRow(null); setMasterNameID(null); setLogData([]); get_LogData('');
        }
    };

    const get_LogData = (MasterNameID,) => {
        const val2 = { 'MasterNameID': MasterNameID, 'PINID': loginPinID }
        fetchPostData("RecordSeal/GetDataNameIncident", val2).then((res) => {
            if (res) {
                setLogData(res);
            } else {
                setLogData([]);
            }
        })
    }

    const columns = [
        {
            name: 'Name Number', selector: (row) => row.NameIDNumber, sortable: true
        },
        {
            name: 'Last Name', selector: (row) => row.LastName, sortable: true
        },
        {
            name: 'First Name', selector: (row) => row.FirstName, sortable: true
        },
        {
            name: 'Middle Name', selector: (row) => row.MiddleName, sortable: true
        },
        {
            name: 'Gender', selector: (row) => row.Gender_Description, sortable: true

        },
        {
            name: 'DOB', selector: (row) => row.DateOfBirth ? getShowingWithOutTime(row.DateOfBirth) : " ", sortable: true
        },
        {
            name: 'Race', selector: (row) => row.Race_Description, sortable: true
        },
        {
            name: 'SSN', selector: (row) => row.SSN, sortable: true
        },
    ]

    const conditionalRowStyles = [
        {
            when: row => row === clickedRow,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];

    //-----------------------Select Charges to be Seal/Unseal----------------------------------------------

    const AddType = [
        { value: 1, label: 'Seal', },
        { value: 2, label: 'Unseal', },
        { value: 3, label: 'both', },
    ]
    useEffect(() => {
        setIncidentID(IncidentID)
    }, [IncidentID])

    const get_SealUnsealData = (IncidentID, LoginPinID) => {
        const val2 = { 'IncidentID': IncidentID, 'PINID': loginPinID }
        fetchPostData("RecordSeal/Get_ChargebySeal", val2).then((res) => {
            if (res) {
                setSealUnsealData(res);
            } else {
                setSealUnsealData([]);
            }
        })
    }

    const changeHandler = (e) => {
        const files = e.target.files
        setSelectedFile(files)
        const nameArray = []
        for (let name of files) {
            nameArray?.push(name?.name)
        }
        setSelectedFileName(nameArray);
        setErrors({ ...errors, 'File_Not_Selected': '' })
    };

    const Add_SealOrUnseal = async (actionType) => {
        const formdata = new FormData();
        const EncFormdata = new FormData();
        const newDoc = []; const EncDocs = [];
        const isSeal = actionType === 'seal';
        const officerID = isSeal ? value.SealOfficerID : value.UnsealOfficerID;
        const files = isSeal ? value.SealFiles : value.UnsealFiles;

        const DateTime = isSeal && datezone ? getShowingDateText(new Date(datezone)) : getShowingDateText(new Date(datezone));
        for (let i = 0; i < selectedFile.length; i++) {
            formdata.append("file", selectedFile[i]);
            EncFormdata.append("file", selectedFile[i]);
        }
        const SealUnsealStatus = isSeal ? 'True' : 'false';
        for (let id of ChargeID) {
            const val = {
                'ChargeID': id,
                'CreatedByUserFK': loginPinID,
                'SealUnsealStatus': SealUnsealStatus,
                [isSeal ? 'DateTimeSeal' : 'DateTimeUnSeal']: DateTime,
                [isSeal ? 'SealFiles' : 'UnsealFiles']: files,
                [isSeal ? 'SealOfficerID' : 'UnsealOfficerID']: officerID,
                [isSeal ? 'SealOfficerID' : 'UnsealOfficerID']: loginPinID
            };
            const values = JSON.stringify(val);
            newDoc.push(values);
            const EncPostData = await Aes256Encrypt(JSON.stringify([JSON.stringify(val)]));
            EncDocs.push(EncPostData);
        }
        formdata.append("Data", JSON.stringify(newDoc));
        EncFormdata.append("Data", EncDocs);
        formdata.append("AgencyID", loginAgencyID);
        EncFormdata.append("AgencyID", loginAgencyID);
        AddDelete_Img('RecordSeal/Insert_RecordSeal', formdata, EncFormdata)
            .then((res) => {
                if (res.success) {
                    const parsedData = JSON.parse(res.data);
                    const message = parsedData.Table[0].Message;
                    toastifySuccess(message); setSelectedFileName([]); setSelectedFile([]); get_SealUnsealData(IncidentID); setSelectedRowStatus(isSeal ? true : false);
                } else {
                    console.log("Something went wrong");
                }
            })
            .catch(err => console.log(err));
    };

    const columns2 = [
        {
            name: 'Incident Number', selector: (row) => row.IncidentNumber
            , sortable: true
        },
        {
            name: 'Reported Date', selector: (row) => row.ReportedDate ? getShowingMonthDateYear(row.ReportedDate) : " ",
            sortable: true
        },
        {
            name: 'Location', selector: (row) => row.CrimeLocation?.trim(), sortable: true
        },
    ]

    const columns1 = [
        { name: 'Charge Code', selector: row => row?.ChargeCode_Description, sortable: true },
        { name: 'Charge Description', selector: row => row?.NIBRS_Description, sortable: true },
        { name: 'Event Number', selector: row => row?.ArrestNumber, sortable: true },
        {
            width: '200px',
            name: 'Date/Time Of Seal',
            selector: (row) =>
                row.DateTimeUnSeal ? getShowingDateText(row.DateTimeUnSeal) : row.DateTimeSeal ? getShowingDateText(row.DateTimeSeal) : " ",
            sortable: true
        },
        {
            width: '120px',
            name: <p className='text-end' style={{ position: 'absolute', top: 8 }}>Attachment</p>,
            cell: row =>
                row?.SealFiles || row?.UnsealFiles ? (
                    <div className="div" style={{ position: 'absolute', top: 4, left: 20 }}>
                        <span
                            onClick={() => window.open(row?.SealFiles || row?.UnsealFiles)}
                            className="btn btn-sm bg-green text-white px-1 py-0"
                        >
                            <i className="fa fa-eye"></i>
                        </span>
                    </div>
                ) : null
        },
        { name: 'Performed By', selector: row => row?.SealOfficer || row?.UnSealOfficer, sortable: true },
        {
            name: 'Status Seal', selector: row => (row?.SealUnsealStatus ? 'Seal' : 'Unseal'), sortable: true
        }
    ];


    const handleRowSelected1 = useCallback(
        debounce(({ selectedRows }) => {
            if (selectedRows.length > 0) {
                const propertyIds = selectedRows.map(row => row.ChargeID);
                const propertyIdss = selectedRows.map(row => row.SealUnsealStatus);
                setChargeID(propertyIds); setSelectedRowStatus(propertyIdss); setSelectedRows(selectedRows);
            } else { setSelectedRows([]); }
        }, 1000),
        []
    );

    const handleRowSelected2 = (selectedRows) => {
        const selectedRow = selectedRows.selectedRows[0];
        if (selectedRow?.IncidentID) {
            setSelectedRowData(selectedRow); setIncidentID(selectedRow?.IncidentID); get_SealUnsealData(selectedRow?.IncidentID);
        } else { setSelectedRowData(null); }
    };

    const ExpandedComponent = () => (
        <DataTable
            dense
            columns={columns1}
            data={filteredData}
            onSelectedRowsChange={handleRowSelected1}
            pagination
            selectableRows
            selectableRowSelected={(row) => (selectedRows)?.some((selectedRow) => selectedRow.ChargeID === row.ChargeID)}
            customStyles={tableCustomStyles}
            conditionalRowStyles={[{ when: row => row?.SealUnsealStatus === true, style: { backgroundColor: '#FFCCCC', color: 'black' } }]}
        />
    );

    useEffect(() => {
        if (value.SealUnseal) {
            const filtered = sealUnsealData?.filter(row => {
                if (value.SealUnseal === 1) return row.SealUnsealStatus === true;
                if (value.SealUnseal === 2) return row.SealUnsealStatus === false;
                return true;
            });
            setFilteredData(filtered);
            if (filtered.length > 0) { setSelectedRowStatus(filtered[0]?.SealUnsealStatus); }
        } else { setFilteredData(sealUnsealData); }
    }, [value.SealUnseal, sealUnsealData]);

    const handleUnsealSubmit = (actionType) => {
        setAction(actionType); Add_SealOrUnseal(actionType); setShowModal(false);
    };

    const check_Validation_Error = (e) => {
        const File_Not_SelectedErr = validate_fileupload(selectedFileName);
        setErrors(prevValues => {
            return { ...prevValues, ['File_Not_Selected']: File_Not_SelectedErr || prevValues['File_Not_Selected'], }
        })
    }
    const { File_Not_Selected } = errors

    useEffect(() => {
        if (File_Not_Selected === 'true') {
            handleUnsealSubmit(actionType);
        }
    }, [File_Not_Selected])

    return (
        <>
            <div className="section-body view_page_design pt-1 p-1 bt">
                <div className="div">
                    <div className="col-12 inc__tabs">
                        <SealUnsealTab />
                    </div>
                    <div className="dark-row">
                        <div className="col-12 col-sm-12">
                            <div className="card Agency incident-card">
                                <div className="card-body">
                                    <div className="col-12 col-md-12 col-lg-12 ">
                                        <div className="row " style={{ marginTop: '-10px' }}>
                                            <div className="col-2 col-md-2 col-lg-1  mt-2">
                                                <label htmlFor="" className='new-label  '>Last Name</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3  text-field mt-1">
                                                <input type="text" name='LastName' autoComplete='off' value={value.LastName} onChange={(e) => { handleChange(e) }} />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1  mt-2">
                                                <label htmlFor="" className='new-label '>First Name</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                                <input type="text" name='FirstName' autoComplete='off' value={value.FirstName} onChange={(e) => { handleChange(e) }} />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1  mt-2 px-0">
                                                <label htmlFor="" className='new-label px-0'>Middle&nbsp;Name</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                                <input type="text" name='MiddleName' autoComplete='off' value={value.MiddleName} onChange={(e) => { handleChange(e) }} />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Gender</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                <Select
                                                    name='SexID'
                                                    value={sexIdDrp?.filter((obj) => obj.value === value?.SexID)}
                                                    options={sexIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                <label htmlFor="" className='new-label'>DOB</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 ">
                                                <DatePicker
                                                    id='DateOfBirth'
                                                    name='DateOfBirth'
                                                    className=''
                                                    onChange={(date) => {
                                                        setdateOfbirth(date); setValue({ ...value, ['DateOfBirth']: date ? getShowingMonthDateYear(date) : null, });
                                                    }}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    isClearable={value?.DateOfBirth ? true : false}
                                                    selected={dateOfbirth}
                                                    placeholderText={value?.DateOfBirth ? value?.DateOfBirth : 'Select...'}
                                                    maxDate={new Date()}
                                                    // showTimeSelect
                                                    timeIntervals={1}
                                                    // timeCaption="Time"
                                                    autoComplete="Off"

                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1  mt-2">
                                                <label htmlFor="" className='new-label '>SSN</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                                <input type="text" name='SSN' autoComplete='off' value={value.SSN} maxLength={9} onChange={(e) => { handleChange(e) }} />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Race</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                <Select
                                                    name='RaceID'
                                                    value={raceIdDrp?.filter((obj) => obj.value === value?.RaceID)}
                                                    options={raceIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'RaceID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Ethnicity</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                <Select
                                                    name='EthnicityID'
                                                    value={ethinicityDrpData?.filter((obj) => obj.value === value?.EthnicityID)}
                                                    options={ethinicityDrpData}
                                                    onChange={(e) => ChangeDropDown(e, 'EthnicityID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            {/* <div className="btn-box col-4  text-right   mb-1 mt-3" >
                                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, value.RaceID, value.EthnicityID, true)}>Search</button>
                                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={onDashboardClose} >Close</button>
                                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={Clear}>Clear</button>
                                            </div> */}

                                            <div className="btn-box col-4  text-right   mb-1 mt-3">
                                                {
                                                    effectiveScreenPermission ? effectiveScreenPermission[0]?.AddOK ?
                                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, value.RaceID, value.EthnicityID, true)}>Search</button>
                                                        : <></> :
                                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, value.RaceID, value.EthnicityID, true)}>Search</button>
                                                }
                                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={onDashboardClose} >Close</button>
                                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={Clear}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-1">
                                        <DataTable
                                            dense
                                            columns={columns}
                                            data={nameSearchValue}
                                            onSelectedRowsChange={handleRowSelected}
                                            pagination
                                            conditionalRowStyles={conditionalRowStyles}
                                            onRowClicked={(row) => {
                                                setClickedRow(row);
                                                if (row?.MasterNameID) { setMasterNameID(row?.MasterNameID); setIncidentID(row?.IncidentID); get_LogData(row?.MasterNameID); }
                                            }}
                                            highlightOnHover
                                            fixedHeaderScrollHeight="150px"
                                            fixedHeader
                                            persistTableHead={true}
                                            customStyles={tableCustomStyles}
                                        />
                                    </div>
                                    <div className="col-12 bg-line py-1 d-flex justify-content-between align-items-center mt-1 text-white mt-3">
                                        <p className="p-0 m-0">Select Charges to be Seal/Unseal</p>
                                        <div className="ml-auto" style={{ width: '200px' }}>
                                            <SelectBox
                                                name="SealUnseal"
                                                options={AddType}
                                                isClearable
                                                placeholder="Filter..."
                                                value={AddType?.filter((obj) => obj.value === value?.SealUnseal)}
                                                onChange={(selectedOption) => {
                                                    setValue({ ...value, SealUnseal: selectedOption ? selectedOption.value : '' });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-1">
                                        <DataTable
                                            dense
                                            columns={columns2}
                                            data={logData} // Your log data
                                            pagination
                                            highlightOnHover
                                            fixedHeaderScrollHeight="190px"
                                            fixedHeader
                                            onSelectedRowsChange={handleRowSelected2}
                                            persistTableHead={true}
                                            customStyles={tableCustomStyles}
                                            selectableRows
                                            expandableRows
                                            expandableRowsComponent={({ data }) =>
                                                selectedRowData ? (
                                                    <ExpandedComponent data={selectedRowData} incidentID={selectedRowData.IncidentID} />
                                                ) : null
                                            }
                                            expandableInheritConditionalStyles
                                        />
                                    </div>
                                    <div className="btn-box text-right mr-1 mt-2">
                                        {selectedRowStatus[0] === false && (
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-success mr-1"
                                                onClick={() => { setShowModal(true); setActionType('seal'); }}
                                            >
                                                Seal
                                            </button>
                                        )}
                                        {selectedRowStatus[0] === true && (
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-success mr-1"
                                                onClick={() => { setShowModal(true); setActionType('unseal'); }}
                                            >
                                                UnSeal
                                            </button>
                                        )}
                                        {/* <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { setShowModal(true); setActionType('unseal'); }}
                                            >
                                                UnSeal
                                            </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for UnSeal */}
            {showModal && (
                <div className="modal fade show" style={{ background: "rgba(0,0,0, 0.5)", display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="col-12 col-md-12 col-lg-12 text-field ">
                                    <h6 htmlFor="sealUnsealData" >Are you sure you want to {actionType === 'seal' ? 'Seal' : 'Unseal'} this record?
                                        First please attach the document to proceed.</h6>
                                    <input className='mt-2 requiredColor' type="file" name='file' onChange={changeHandler} required />
                                    {selectedFileName?.length > 0 &&
                                        <i className="fa fa-close" style={{ position: "absolute", right: "1rem", top: "7px" }} onClick={() => { setSelectedFileName(''); document.querySelector("input[type='file']").value = "" }}></i>}
                                </div>
                                {errors.File_Not_Selected !== 'true' ? (
                                    <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.File_Not_Selected}</p>
                                ) : null}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { setShowModal(false); setErrors('') }}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={() => check_Validation_Error()}>Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SealUnseal;
function validate_fileupload(fileName) {
    if (fileName.length > 0 && fileName.length < 2) {
        return 'true';
    } else if (fileName.length > 1) {
        toastifyError("Please Select Single File");
    } else {
        return 'Please Select File*';
    }

}
