import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Decrypt_Id_Name, tableCustomStyles } from '../../Common/Utility';
import { fetchPostData } from '../../hooks/Api';
import Tab from '../../Utility/Tab/Tab';

const ErrorLog = () => {

    const [logData, setLogData] = useState([]);

    const get_LogData = () => {
        const val = {
            // 'IncidentID': IncidentID
        }
        fetchPostData('Log/GetData_ErrorLog').then((res) => {
            if (res) {
                setLogData(res);
            } else {
                setLogData([]);
            }
        })
    }

    const columns = [
        {
            name: 'Column Name',
            selector: (row) => row.ColumnName,
            sortable: true
        },
        {
            name: 'Old Value',
            selector: (row) => row.OldValue,
            sortable: true
        },
        {
            name: 'New Value',
            selector: (row) => row.NewValue,
            sortable: true
        },
        {
            name: 'Officer_Name',
            selector: (row) => row.Officer_Name,
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => row.Status,
            sortable: true
        },
    ]

    return (
        <div className=" section-body pt-1 p-1 bt" >
            <div className="div">
                <div className="col-12  inc__tabs">
                    <Tab />
                </div>
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency ">
                            <div className="card-body">
                                <div className="col-12 px-0 mt-1">
                                    <DataTable
                                        dense
                                        columns={columns}
                                        data={logData ? logData : []}
                                        pagination
                                        selectableRowsHighlight
                                        highlightOnHover
                                        customStyles={tableCustomStyles}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ErrorLog