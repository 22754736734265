import React from 'react'

const DocumentHistory = () => {
    return (
        <div>
            <h1>Document History</h1>
        </div>
    )
}

export default DocumentHistory
