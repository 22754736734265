import React, { useState, useEffect, useContext, useRef } from "react";
import { Decrypt_Id_Name, } from "../Common/Utility";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AgencyContext } from "../../Context/Agency/Index";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Knob } from "primereact/knob";
import incident from '../../dashboardimg/incident.png';
import name from '../../dashboardimg/name.png';
import property from '../../dashboardimg/property.png';
import vehicle from '../../dashboardimg/vehicle.png';
import arrest from '../../dashboardimg/arrest.png';
import warrant from '../../dashboardimg/warrant.png';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { get_LocalStoreData } from "../../redux/actions/Agency";
import useNoBackNavigation from "../../useNoBackNavigation";
import { values } from "pdf-lib";


const Dashboard = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const IncRef = useRef(null)

  // const localStoreData = useSelector((state) => state.Agency.localStoreData);
  // const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

  const { setUpdateCount, updateCount, setIncidentStatus, setPropertyCount, setTabCount, setNameTabCount, setIncidentCount, setVehicleCount, GetDataTimeZone, datezone } = useContext(AgencyContext);
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [greet, setGreet] = useState('');
  const [userName, setUserName] = useState('');
  const [startDate, setStartDate] = useState();
  const [ipAddress, setIpAddress] = useState('');
  const [count, setCount] = useState(1);


  // useEffect(() => {
  //   if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
  //     if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStoreData?.UserName) {
  //     setUserName(localStoreData?.UserName);
  //   }
  // }, [localStoreData]);

  // const myDate = new Date();
  // const hrs = myDate.getHours();

  // useEffect(() => {
  //   if (hrs < 12) {
  //     setGreet('Good Morning')
  //   } else if (hrs >= 12 && hrs <= 17) {
  //     setGreet('Good Afternoon')
  //   } else if (hrs >= 17 && hrs <= 24) {
  //     setGreet('Good Evening')
  //   } else {
  //     setGreet('')
  //   }
  // }, []);


  useEffect(() => {
    if (localStoreData) {
      const defaultDate = datezone ? new Date(datezone) : null;
      GetDataTimeZone(localStoreData?.AgencyID);
      setStartDate(new Date(defaultDate));
    }
  }, [localStoreData, datezone]);

  const clickOnIncident = () => {
    setUpdateCount(updateCount + 1);
    setIncidentStatus(false);
    setIncidentCount([]);
    setTabCount([]);
    setNameTabCount([]);
    setPropertyCount([]);
    setVehicleCount([]);

  }

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  // code 16-05-2024
  // const IsLoginSession = sessionStorage.getItem('is-login') ? sessionStorage.getItem('is-login') : false;

  // const currentLocation = window.location.pathname + window.location.search + window.location.hash;

  // useEffect(() => {
  //   if (IsLoginSession && window.location.pathname === "/dashboard-page") {
  //     if (window.location.pathname === "/dashboard-page") {
  //       preventBack();
  //     }
  //   }
  // }, [window.location.pathname, window.location.href]);

  // function preventBack() {
  //   const currentPath = location.pathname;
  //   navigate('/dashboard-page', { state: { from: currentPath } });

  //   window.onpopstate = function (event) {
  //     if (event.state && event.state.url === currentLocation || currentLocation === window.location.pathname) {
  //       window.history.go(1);
  //       // window.history.pushState({}, '', '/dashboard-page');
  //     }
  //   };
  //   window.history.pushState({ url: currentLocation }, '');
  //   window.history.pushState(null, null, currentLocation);
  // }

  function clickButton() {
    // console.count()
    IncRef.current.focus();

    const element = document.getElementById("myButton");
    element.click();
  }


  return (
    <>

      <div ref={IncRef} className="section-body mt-4" style={{ margin: '10px 10px 10px 15px' }}>
        <div className="row clearfix">
          <div className="main-dashboard col-12 mb-2 mt-2">
            <div className="d-flex mt-2">
              <div className="md col-9">
                <h6 className="d-inline-block form-set p-2" >
                  {/* Welcome ,{userName ? userName : ''} */}
                </h6>
                <h4 className="d-inline-block ml-2">{greet} </h4>
                <small className="d-block">
                </small>
              </div>
              <div className="col-3">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  maxDate={new Date(datezone)}
                  open={false}
                />
              </div>
            </div>
            <div className=" col-12 mb-3 mt-2">
              <div className="box-container ">
                <div className="box">
                  <Link to={`/Inc-Home?IncId=${0}&IncNo=${''}&IncSta=${false}`} onClick={clickOnIncident}>
                    <img src={incident} alt="" className="dashimg" />
                    <p className="dashname">Incident</p>
                  </Link>
                </div>
                <div className="box">
                  <Link to={`/Name-Home?page=MST-Name-Dash&IncId=${0}&IncNo=${0}&IncSta=${0}&NameID=${0}&MasterNameID=${0}&NameStatus=${false}`}>
                    <img src={name} alt="" className="dashimg" />
                    <p className="dashname">Name</p>
                  </Link>
                </div>
                <div className="box">
                  <Link to={`/Prop-Home?page=MST-Property-Dash&ProId=${0}&MProId=${0}&ProSta=${false}`}>
                    <img src={property} alt="" className="dashimg" />
                    <p className="dashname">Property</p>
                  </Link>
                </div>
                <div className="box">
                  <Link to={`/Vehicle-Home?page=MST-Vehicle-Dash&?VehId=${0}&?MVehId=${0}`}>
                    <img src={vehicle} alt="" className="dashimg" />
                    <p className="dashname">Vehicle</p>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4" style={{ margin: '10px 10px 10px 15px', padding: '0px 0px' }}>
        <div className="row clearfix mt-2">
          <div className="main-dashboard col-12 mb-1">
            <div className="d-flex dash-theme">
              <div className="md col-9">
                <div className="row">
                  <div className="col-2" style={{ borderRight: '1px solid gray', color: "#e8edf4" }}>
                    <h6>Total Incident</h6>
                    <h5 className="text-bold pl-2 mt-0 mb-0 text-white">110</h5>
                  </div>
                  <div className="col-2" style={{ borderRight: '1px solid gray', color: "#e8edf4" }}>
                    <h6>Active Incident</h6>
                    <h5 className="text-bold pl-2 mt-0 mb-0 text-white">90</h5>
                  </div>
                  <div className="col-2" style={{ borderRight: '1px solid gray', color: "#e8edf4" }}>
                    <h6>Completed Incident</h6>
                    <h5 className="text-bold pl-2 mt-0 mb-0 text-white">10</h5>
                  </div>
                </div>
              </div>
              <div className="col-3 mt-2">
                <Select
                  // value={[{ value: 1, label: 'Welcome To RMS' }]}
                  // options={[
                  //   { value: 1, label: 'Welcome To RMS' },
                  //   { value: 2, label: 'Welcome To CAD' }
                  // ]}
                  name=''
                  menuIsOpen={false}
                  styles={customStylesWithOutColor}
                  isClearable={false}
                  placeholder="Select Incident..."
                />
              </div>
            </div>
            <div className=" col-12 mb-5 mt-3">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="card-body text-center">
                    <Knob value={20} readOnly rangeColor="#708090" valueColor="#081c3c" size={100} step={10} strokeWidth={12} valueTemplate={'20%'} />
                    <h6>Planned</h6>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card-body text-center">
                    <Knob value={50} readOnly rangeColor="#708090" valueColor="#081c3c" size={100} step={10} strokeWidth={12} valueTemplate={'50%'} />
                    <h6>In progress</h6>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card-body text-center">
                    <Knob value={70} readOnly rangeColor="#708090" valueColor="#081c3c" size={100} step={10} strokeWidth={12} valueTemplate={'70%'} />
                    <h6>Completed</h6>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card-body text-center">
                    <Knob value={90} readOnly rangeColor="#708090" valueColor="#081c3c" size={100} step={10} strokeWidth={12} valueTemplate={'90%'} />
                    <h6>In Completed</h6>
                  </div>
                </div>

                <button id="myButton" style={{ display: 'none' }} ></button>
                <input type="text" value={ipAddress} style={{ display: 'none' }} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default Dashboard;
