import React, { useState, useEffect, useContext } from 'react'
import { AgencyContext } from '../../../Context/Agency/Index'
import ArrestMainTab from '../../Utility/Tab/ArrestMainTab'
import { base64ToString } from '../../Common/Utility'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Log from '../Log/Log'
import DocumentAccess from './DocumentTab/DocumentAccess/DocumentAccess'
import DocumentHistory from './DocumentHistory/DocumentHistory'
import Home from './DocumentTab/Home/Home'
import Tab from '../../Utility/Tab/Tab'
// import { Tab } from 'bootstrap'

const Document_Add_Up = () => {

    const dispatch = useDispatch()
    const arrestUpdateStatus = useSelector((state) => state.Agency.arrestUpdateStatus);
    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const { updateCount, EditArrestStatus, setEditArrestStatus, tabCountArrest, get_Arrest_Count, changesStatus } = useContext(AgencyContext)

    const iconHome = <i className="fa fa-home" style={{ fontSize: '20px' }}></i>
    const [showPage, setShowPage] = useState('home');
    const [status, setStatus] = useState()
    const [showJuvinile, setShowJuvinile] = useState(false);
    const [showPoliceForce, setShowPoliceForce] = useState(false);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    let ArrestId = query?.get('ArrestId');
    let IncID = query?.get('IncId');
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    var DocSta = query?.get('DocSta');
    var ArrNo = query?.get("ArrNo");
    let MstPage = query?.get('page');
    var Name = query?.get("Name");
    var ChargeSta = query?.get('ChargeSta');
    let documentID = query?.get('documentId');

    let DecArrestId = 0, DecIncID = 0, DecdocumentID = 0;


    if (!IncID) IncID = 0;
    else DecIncID = parseInt(base64ToString(IncID));

    if (!documentID) documentID = 0;
    else DecdocumentID = parseInt(base64ToString(documentID));

    useEffect(() => {
        if (DocSta === 'true' || DocSta === true) {
            setStatus(true);
        } else if (DocSta === 'false' || DocSta === false) {
            setStatus(false);
        }
        setShowPage('home');
    }, [DocSta, localStoreData, updateCount]);
    console.log(DecdocumentID)

    return (
        <div className=" section-body pt-1 p-1 bt" >
            <div className="div">
                <div className="col-12  inc__tabs">
                    <Tab />
                </div>
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card ">
                            <div className="card-body" >
                                <div className="row " style={{ marginTop: '-18px', marginLeft: '-18px' }}>
                                    <div className="col-12 name-tab">
                                        <ul className='nav nav-tabs'>
                                            <Link
                                                // className={`nav-item ${showIncPage === 'Pin Activity' ? 'active' : ''} ${!status ? 'disabled' : ''}`}

                                                className={`nav-item ${showPage === 'home' ? 'active' : ''}`}
                                                // to={
                                                //     `/Document-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}`
                                                // }
                                                // to={`/Document-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&documentId=${documentID}&DocSta=${DocSta}`}
                                                to={`/Document-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&DocSta=${true}&documentId=${(documentID)}`}

                                                // to={`/ Arrest - Home ? IncId = ${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${ArrestId}&ArrestSta=${ArrestSta}&ArrNo=${ArrNo}`}
                                                style={{ color: showPage === 'home' ? 'Red' : '#000' }}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                aria-current="page"
                                                onClick={() => { if (!changesStatus) { setShowPage('home') } }}>
                                                {iconHome}
                                            </Link>

                                            <span
                                                className={`nav-item ${showPage === 'DocumentAccess' ? 'active' : ''}${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'DocumentAccess' ? 'Red' : '#000' }}
                                                // style={{ color: showPage === 'DocumentAccess' ? 'Red' : tabCountArrest?.PropertyCount > 0 ? 'blue' : '#000' }} aria-current="page"
                                                onClick={() => {
                                                    if (!changesStatus) { setShowPage('DocumentAccess') }
                                                }}>
                                                Document Access
                                                {`${tabCountArrest?.PropertyCount > 0 ? '(' + tabCountArrest?.PropertyCount + ')' : ''}`}
                                            </span>
                                            <span
                                                className={`nav-item ${showPage === 'DocumentHistory' ? 'active' : ''} ${!status ? 'disabled' : ''}`}
                                                data-toggle={changesStatus ? "modal" : "pill"}
                                                data-target={changesStatus ? "#SaveModal" : ''}
                                                style={{ color: showPage === 'DocumentHistory' ? 'Red' : '#000' }}

                                                // style={{ color: showPage === 'CriminalActivity' ? 'Red' : tabCountArrest?.CriminalActivityCount > 0 ? 'blue' : '#000' }} aria-current="page"
                                                onClick={() => {
                                                    if (!changesStatus) { setShowPage('DocumentHistory') }
                                                }}>
                                                Document History
                                                {/* {`${tabCountArrest?.CriminalActivityCount > 0 ? '(' + tabCountArrest?.CriminalActivityCount + ')' : ''}`} */}
                                            </span>
                                        </ul>
                                    </div>
                                </div>
                                {
                                    showPage === 'home' ?
                                        <Home {...{ DecIncID, status, setStatus, DecdocumentID }} />
                                        :
                                        showPage === 'DocumentAccess' ?
                                            <DocumentAccess {...{ DecArrestId, DecIncID, status, setStatus, DecdocumentID }} />
                                            :
                                            showPage === 'DocumentHistory' ?
                                                <DocumentHistory {...{ DecArrestId, DecIncID }} />
                                                :
                                                null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default Document_Add_Up